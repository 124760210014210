import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import AccordianValuation from "../components/AccordianValuation/AccordianValuation"
import AreaGuidesDetailBanner from "../components/AreaGuidesDetailBanner/AreaGuidesDetailBanner"
import ImageContentComponent from "../components/ImageContentComponent/ImageContentComponent"
import Map from "../components/PropertyMap/Map"
import Layout from "../components/layout"
import SEO from "../components/seo"
import qs from "qs"
import logoBlack from "../images/logo-white.svg"
import { removeDataAttributes } from "../components/Common/utils"
import { GetTransformedUrl } from "../common/stb-jamstack-ggfxclient/client"
import LandingContent from "../components/LandingContent/LandingContent"
import ValuationModule from "../components/ValuationModule/ValuationModule"
import OffplanConsultant from "../components/OffplanConsultant/OffplanConsultant"
import PropertySlider from "../components/PropertySlider/PropertySlider"
import _ from "lodash"
import LandingStaticCard from "../components/LandingStaticCard/LandingStaticCard"
import ContactComponent from "../components/ContactComponent/ContactComponent"
import SecondaryAreaBanner from "../components/SecondaryAreaBanner/SecondaryAreaBanner"
import PopularAreaComponent from "../components/PopularAreaComponent/PopularAreaComponent"
import GuidesCardComponent from "../components/GuidesCardComponent/GuidesCardComponent"
import AreaGuideHeader from "../components/AreaGuideHeader/AreaGuideHeader"
import { Helmet } from "react-helmet"



const AreaGuideDetails = ({ data,pageContext }) => {
  const [areaData, setAreaData] = useState(data?.strapiAreaGuide);
  const areaGuideData = areaData
  const [isPreviewEnabled, setIsPreviewEnabled]=useState(false)

  const [teamData, setTeamData] = useState([])
  const [propertyData, setPropertyData] = useState([])


  const windowSearch= typeof window!=="undefined"?window.location.search:""
  const [loading, setLoading] = useState(() => {
    const checkurlParams = new URLSearchParams(windowSearch);
    const checkisPreview = checkurlParams.get('debug') === 'preview';
    return checkisPreview;
  });

  const [enablePreview, setEnablePreview] = useState(pageContext?.strapi_preview == "true" ? true : false) // since its string

  const [id, setId] = useState([])

  let idArray

  useEffect(() => {
    setTimeout(() => {
      const nodeList =
        typeof document !== "undefined"
          ? document.querySelectorAll("section")
          : ""
      const array = [...nodeList]
      const filteredArray = array.filter(element => element.hasAttribute("id"))
      idArray = filteredArray.map(element => element.getAttribute("id"))
      if (idArray) {
        setId(idArray)
      }
    }, 500)
  }, [setId])

  const property_details_map_data = {
    latitude: areaGuideData?.latitude,
    longitude: areaGuideData?.longitude,
  }

  const PageData =
    data?.allStrapiPage?.edges?.length > 0
      ? data?.allStrapiPage?.edges[0]?.node
      : ""

  let popUpData = ""

  if (PageData?.add_page_modules?.length > 0) {
    const results = PageData?.add_page_modules?.filter(
      item => item.__typename === "STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT"
    )
    if (results?.length > 0) {
      popUpData = results[0]
    }
  }

  const fetchTeamData =async(url) =>{
    try{
      const response = await fetch(url,{
        headers: new Headers({
          Authorization: `Bearer ${process.env.GATSBY_STRAPI_API_TOKEN}`,
        }),
      });
      const result = await response.json()
      setTeamData(removeDataAttributes(result?.data))
    }
    catch(error){
      console.log(error,'area guide team')
    }
  }

  useEffect(()=>{
    if (!_.isEmpty(teamData)) return

    const query = qs.stringify(
      {
        filters: {
          choose_areas: {
            area_guide: {
              title: {
                $eq: areaGuideData?.title ? areaGuideData.title : "", // dynamic areaName, similar to "Arabian Ranches"
              },
            },
            publish: {
              $eq: true, 
            },
          },
        },
        sort: ["sort:asc"], // Sorting by 'dort' in ascending order
        pagination: {
          pageSize: 10, 
        },
        populate: {
          image: true, // Including images in the response
          team_member:true
        },
      },
      {
        encodeValuesOnly: true, 
      }
    );
    const url = `${process.env.GATSBY_STRAPI_SRC}/api/teams?${query}&populate=ggfx_results`;

    fetchTeamData(url)
  },[])
  const filteredProperty = data?.allProperties?.edges;

  //property fetch

  const fetchPropertyData =async(url)=>{
    try{
      const response = await fetch(url,{
        headers: new Headers({
          Authorization: `Bearer ${process.env.GATSBY_STRAPI_API_TOKEN}`,
        }),
      });
      const result = await response.json()
      setPropertyData(result?.data)
    }
    catch(error){
      console.log(error,'area guide property')
    }
  }


  useEffect(()=>{
    if (!_.isEmpty(propertyData)) return
    const query = qs.stringify(
      {
        filters: {
          $or: [
            {
              community: {
                $eq: areaGuideData?.title,
              },
            },
            {
              area: {
                $eq	: areaGuideData?.title,
              },
            },
          ],
         
          search_type: {
            $eq: 
              "sales"
          },
          status:{
            $ne:'Off Market'
          },
          department: {
            $eq: "residential",
          },
          publish: {
            $eq: true,
          },
        },
       
        pagination: {
          pageSize: 12,
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )
   
    const url = `${process.env.GATSBY_STRAPI_SRC}/api/properties?${query}&populate=ggfx_results`;

    fetchPropertyData(url)
  },[])
  //



  const query = qs.stringify(
    {
      populate: {
        ggfx_results:"*",
        banner_image:"*",
        tile_image:"*",
        cta1_link:"*",
        cta2_link:"*",
        area:"*",
        team:"*",
        seo:"*",
        secondary_banner:"*",
        guide_content:{
          on: {
            "page-modules.area-listings":{
              populate:{
                list_items:{
                  populate:{
                    image: "*",
                  }
                }
              }
            },
            "components.title-desc":{
              populate:"*"
            },
            "page-modules.secondary-banner":{
              populate:{
                image: "*"
              }
            },
            "page-modules.image-content":{
              populate:{
                left_right_block:{
                  populate:{
                    image: "*",
                    menu: "*",
                  }
                }
              }
            },
            "page-modules.accordion":{
              populate:{
                add: "*"
              }
            },
            "page-modules.guides-cards":{
              populate:{
                items:{
                  populate:{
                    image: "*",
                    link: "*"
                  }
                }
              }
            },
            "page-modules.two-columns":{
                 populate:{
                  image:"*",
                  about_developer:"*",
                  menu:{
                    populate:{
                      parent:"*"
                    }
                  }
              }
            },
            "page-modules.static-card-items":{
              populate:{
                cards:{
                  populate:{
                    cta:{
                      populate:{
                        link:{
                          populate:{
                            parent:"*"
                          }
                        }
                      }
                    }
                  }
                }
              }
            },
            "page-modules.global-module":{
              populate:{
                select_module:"*"
              }
            },
            "page-modules.custom-modules":{
              populate:{
                select_module:"*"
              }
            },
            "page-modules.valuation-image":{
              populate:{
                cta_item:{
                  populate:{
                    link: "*",
                  }
                },
                add_image:{
                  populate:{
                    images: "*",
                  }
                }
              }
            },
           
            
          }
        }
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('debug') === 'preview';
    const apiUrl=`${process.env.GATSBY_STRAPI_SRC}/api/area-guides/${urlParams.get('strapi_id')}?${query}`

    const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;

    if(isPreview){
      setIsPreviewEnabled(true)
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        const getData = await response.json();
        const previewData=removeDataAttributes(getData)
        setLoading(false)
        setEnablePreview(false)
        let myPrevData;
        if(areaData){
          myPrevData = areaData
        } else {
          myPrevData = {};
          myPrevData.tile_image = {}
          myPrevData.banner_image = {}
          myPrevData.content=[]
          myPrevData.area_listings = {}

          myPrevData.secondary_banner = {}
          myPrevData.guides_cards=[]
          myPrevData.id = urlParams.get('strapi_id')
        }
        if(previewData){
          myPrevData.area = previewData?.area
          myPrevData.title = previewData?.title
          myPrevData.banner_image = previewData?.banner_image
          myPrevData.cta1_title = previewData?.cta1_title
          myPrevData.cta1_custom_link = previewData?.cta1_custom_link

          myPrevData.cta1_link = previewData?.cta1_link
          myPrevData.cta2_custom_link = previewData?.cta2_custom_link
          myPrevData.cta2_link = previewData?.cta2_link
          myPrevData.cta2_title = previewData?.cta2_title

          myPrevData.ggfx_results = previewData?.ggfx_results
          myPrevData.imagetransforms = previewData?.imagetransforms
          myPrevData.latitude = previewData?.latitude
          myPrevData.longitude = previewData?.longitude

          myPrevData.slug = previewData?.slug
          myPrevData.team = previewData?.team
          myPrevData.tile_image = previewData?.tile_image
          myPrevData.longitude = previewData?.longitude
          myPrevData.content = previewData?.content
          myPrevData.banner_title = previewData?.banner_title
          myPrevData.form_title = previewData?.form_title
          myPrevData.description = previewData?.description
          myPrevData.guide_content = previewData?.guide_content
          setAreaData(areaData => ({ ...areaData, myPrevData }));
          sessionStorage.setItem("previewMeta",JSON.stringify(previewData?.seo))
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };
    fetchData();
    }
    else{
      setLoading(false)
      setIsPreviewEnabled(false)
    }

  },[])

  if(loading){
    return (
      <section className={"loader-wrapper"}>
        <div id="loader-wrapper">
          <div id="loader" class="new-loader">
            <img
              className="logo-white loader-logo"
              src={logoBlack}
              alt="logo"
            />
          </div>
        </div>
      </section>
    )
  }

  if(enablePreview){
    return null
  }

  const datehandler = (fixedDate,itemdate) =>{
      const date1 = new Date(fixedDate)
      const date2 = new Date(itemdate)
      if(date1>date2){
        return false
      }
      else{
        return true
      }
  }

  const fixedDate = "2022-10-23T12:21:12.012Z"

  const itemdate = areaGuideData?.createdAt

  const dateResult =  datehandler(fixedDate, itemdate)

  const blockData = areaGuideData?.guide_content?.filter(item=>item.__typename === "STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT");
  const blockItems = blockData?.length>0 ?  blockData[0]?.left_right_block :''

  const twoColumnData = areaGuideData?.guide_content?.filter(item=>item.__typename === "STRAPI__COMPONENT_PAGE_MODULES_TWO_COLUMNS");

  const faqModule = areaGuideData?.guide_content?.filter(item=>item.__typename === "STRAPI__COMPONENT_PAGE_MODULES_ACCORDION");
  let areaGuideSchemaData=[]
  if(blockItems?.length>0){
  blockItems?.map(item=>{
    areaGuideSchemaData.push({
      "@type": "Thing",
      name:item?.title,
      description:item?.description?.data?.description?.replace(/<[^>]*>/g, '')
    },)
  })
  }

  if(twoColumnData?.length>0){
  twoColumnData?.map(item=>{
    if(item?.about_developer?.length>0){
      let combinedDescription=''
      item?.about_developer?.map((dev) => {
        combinedDescription += `${dev?.title}\n${dev?.content?.data?.content?.replace(
          /<[^>]*>/g,
          "" 
        )}\n\n`; 
      });
      areaGuideSchemaData.push({
          "@type": "Thing",
          name:item?.title,
          description:combinedDescription?.trim()
        },)
    }
  })
  }

  let faqSchema = []

  const faqData = faqModule?.length> 0 ? faqModule[0]?.add?.length>0 ? faqModule[0]?.add :'' :''

  if(faqData?.length>0){
    faqData?.map(item=>{
      faqSchema.push({
        "@type": "Question",
        name: item?.title,
        acceptedAnswer: {
        "@type": "Answer",
        text: item?.content?.data?.content?.replace(/<[^>]*>/g, '')
        }
      })
    })
  }


  const areaGuideSchema ={
    "@context": "https://schema.org",
    "@type": "Guide",
    "name": areaGuideData?.title,
    "description": areaGuideData?.seo?.metaDescription,
    "url": "https://www.hausandhaus.com/guides/area-guides-dubai/al-furjan/",
    "mainEntity": areaGuideSchemaData,
  }

  const FAQschema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqSchema
  }

  return (
    <Layout
      popUpData={popUpData}
      nonpropertyDetails
      isSeachpage={true}
      layout={"sticky-module"}
    >
       <Helmet>
      <script
        type="application/ld+json"
        
      >
        {JSON.stringify(areaGuideSchema, null, 2)}
      </script>
      <script
        type="application/ld+json"
        
      >
        {JSON.stringify(FAQschema, null, 2)}
      </script>
      </Helmet>
      <AreaGuidesDetailBanner isPreviewEnabled={isPreviewEnabled} areaGuideData={areaGuideData} />
      {!dateResult &&
      id?.length > 0 && <AreaGuideHeader idArray={id} area />
      }
      {areaGuideData?.guide_content?.map((item, index) => {
        return (
          <div>

            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_AREA_LISTINGS" ||
              item?. __component === "page-modules.area-listings"
              )&&  !dateResult && (
              <div>
                <PopularAreaComponent pageName="areaGuide" data={item} isPreviewEnabled={isPreviewEnabled}/>
              </div>
              )
            }

            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_SECONDARY_BANNER" ||
              item?. __component === "page-modules.secondary-banner"
              )&& !dateResult && (
              <div>
                <SecondaryAreaBanner isPreviewEnabled={isPreviewEnabled} data={item} pageData={areaGuideData} />
              </div>
            )}

            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GUIDES_CARDS" || 
              item?.__component === "page-modules.guides-cards"
              ) && !dateResult && (
              <GuidesCardComponent isPreviewEnabled={isPreviewEnabled} data={item} />
            )}

            {(item?.__typename === "STRAPI__COMPONENT_COMPONENTS_TITLE_DESC" ||
              item?.__component === "components.title-desc") && dateResult && (
              <LandingContent isAreaGuideDetails isPreviewEnabled={isPreviewEnabled} data={item} />
            )}

            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_TWO_COLUMNS" || item?.__component === "page-modules.two-columns")
              && (
              <ImageContentComponent
                isPreviewEnabled={isPreviewEnabled}
                data={item}
                pageData={areaGuideData}
                pageName="areaGuide"
                areaGuideDetails
                dateResult={dateResult ? true:'hide' }
              />
            )}

            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES" || 
              item?.__component === "page-modules.custom-modules") &&
              item?.select_module === "consultants" && dateResult && (
                <OffplanConsultant
                  itemData={item}
                  teamData={teamData}
                  pageData={areaGuideData}
                  isPreviewEnabled={isPreviewEnabled}
                  isAreaGuideDetails
                />
              )}

            {(item?.__typename === "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" || 
              item?.__component === "page-modules.global-module"
            ) && (
            item.select_module === "area_guide_form"&& dateResult &&
            <ContactComponent  pageData={areaGuideData} 
              isPreviewEnabled={isPreviewEnabled} isAreaGuideDetails/>
            
            )} 

            {(item?.__typename === "STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES" || 
              item?.__component === "page-modules.custom-modules")
             && (
            item.select_module === "property_slider"&& dateResult &&
                <PropertySlider isAreaGuideDetails title={item?.title} filteredProperty={propertyData}/>
            )} 


              {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_VALUATION_IMAGE" ||
              item?.__component ==="page-modules.valuation-image")
              && dateResult && (
              <ValuationModule
                valuationData={item}
                isAreaGuideDetails
                imageData={item}
                PageData={areaData}
                isPreviewEnabled={isPreviewEnabled}
              />
            )}

            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT" || 
              item?. __component === "page-modules.image-content"
              )&& (
              <div>
                <ImageContentComponent
                  data={item}
                  pageData={areaGuideData}
                  pageName="areaGuide"
                  isPreviewEnabled={isPreviewEnabled}
                />
              </div>
            )}
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_ACCORDION" || 
              item?.__component === "page-modules.accordion"
              ) && (
              <AccordianValuation
                add_toggle={item?.add}
                title={item?.title}
                content={item?.content}
                accordianPage="area-guide-details"
                isPreviewEnabled={isPreviewEnabled}
              />
            )}
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" || 
              item?.__component === "page-modules.global-module"
              ) &&
              item?.select_module === "area_guide_map" && (
                <section className="area-guide-map section_items" id="map">
                  <Map
                    property_details_map_data={property_details_map_data}
                    areaGuide
                  />
                </section>
              )}

          {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS" || 
              item?.__component === "page-modules.static-card-items") && dateResult && (
              <LandingStaticCard isPreviewEnabled={isPreviewEnabled}
              isDeveloperDetails data={item} 
              />
            )}

           
          </div>
        )
      })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const areaGuideData = data?.strapiAreaGuide


  let myimgtransforms = ""
  if (areaGuideData?.imagetransforms?.banner_image_Transforms) {
    myimgtransforms = JSON.parse(
      areaGuideData?.imagetransforms?.banner_image_Transforms
    )
  }

  let myimgtransforms1 = ""
  if (areaGuideData?.imagetransforms?.metaog_Transforms) {
    myimgtransforms1 = JSON.parse(areaGuideData?.imagetransforms?.metaog_Transforms)
  }


  const getImg=areaGuideData?.banner_image?.url?areaGuideData?.banner_image?.url:""
  const findImage = getImg?.substring(getImg?.indexOf("i"));

  
  let imageExtension=getImg?getImg?.split("."):""
  let ext=imageExtension?.length>0?imageExtension?.pop():""
  

  let imageUrl=""

  if(myimgtransforms1){
    const ProcessedResult=findImage&&myimgtransforms1[findImage]?myimgtransforms1[findImage][ext]:""
    imageUrl=ProcessedResult?ProcessedResult["600x400"]:getImg
  }

  else if(myimgtransforms){
  // ogRender()
  const ProcessedResult=findImage&&myimgtransforms[findImage]?myimgtransforms[findImage]["webp"]:""
  imageUrl=ProcessedResult?ProcessedResult["744x650"]:areaGuideData?.banner_image?.url
  }

  //new ggfx image
    const getImage =
    areaGuideData?.banner_image?.url
        ? areaGuideData?.banner_image.url.replace(
            "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/",
            "",
          )
        : ""
    const filterImage =
      getImage && areaGuideData?.ggfx_results?.length > 0
        ? areaGuideData?.ggfx_results?.filter((item) => item.src_cftle === getImage)
        : ""
  
    // filter image with image size
    const getOGImg =
      filterImage?.length > 0 && filterImage[0].transforms?.length > 0
        ? filterImage[0].transforms.filter((item) => item.transform === "600x400"&&item.format===ext)
        : ""
  
     //to get image in uploaded format   
    const ogRenderNew = ()=>{
      GetTransformedUrl({
        srcCftle: findImage,
        format: ext,
        transform:"600x400",
        id: areaGuideData?.strapi_id,
        field: "metaog",
        contentType: "area-guide"
      })
    }
  
    useEffect(()=>{
      if(!getOGImg?.length>0)
          ogRenderNew()
    },[]) 
  
    //----
    const pageImage=getOGImg?.length>0?getOGImg[0].url:imageUrl

  // const pageMetaTitle = areaGuideData?.seo?.metaTitle
  // const pageMetaDesc = areaGuideData?.seo?.metaDescription

  const metaTitle = areaGuideData?.seo?.metaTitle? `${areaGuideData?.seo?.metaTitle}`: `${areaGuideData?.banner_title}`
  const metaDesc = areaGuideData?.seo?.metaDescription ? areaGuideData?.seo?.metaDescription :
   `Know more about ${areaGuideData?.banner_title} here. Contact one of our real estate agents for assistance in finding the right Properties for you in ${areaGuideData?.banner_title}.`

   let isPreview=""
  if(typeof window!=="undefined"){
  const urlParams = new URLSearchParams(window.location.search);
   isPreview = urlParams.get('debug') === 'preview';
  }

  const [preMetaData, setPreMetaData] = useState("")

  const [preMetaTitle, setPreMetaTitle] = useState(metaTitle)



  useEffect(() => {
    const timer = setTimeout(() => {
      if (typeof window !== "undefined" && isPreview) {
        try {
          const sessionMetaData = sessionStorage.getItem("previewMeta");
          if (sessionMetaData) {
            const parsedData = JSON.parse(sessionMetaData);
            setPreMetaData(parsedData);

            if(parsedData?.metaTitle){
              setPreMetaTitle(parsedData?.metaTitle)
            }
            else{
              setPreMetaTitle(`${areaGuideData?.banner_title}`)
            }
          }
        } catch (error) {
          console.error("Error parsing session storage data:", error);
        }
      }
    }, 4000);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, [isPreview]);

  useEffect(() => {
    if(isPreview){
      document.title =  `${preMetaTitle} | haus & haus`;
    }
  }, [preMetaTitle]);


  return <SEO title={(isPreview && preMetaTitle) ? `${preMetaTitle} | `: `${metaTitle} | `} description={metaDesc} imageUrl={pageImage}></SEO>
}

export default AreaGuideDetails

export const query = graphql`
  query ($page_id: Int) {
    strapiAreaGuide(strapi_id: { eq: $page_id }) {
      banner_image {
        url
        alternativeText
      }
      seo{
        metaDescription
        metaTitle
      }
      createdAt
      banner_title
      cta1_custom_link
      latitude
      longitude
      ggfx_results {
        src_cftle
        transforms {
          format
          url
          transform
        }
      }
        form_title
        description {
          data {
            description
          }
        }
        email_id
        whatsapp_number
      imagetransforms {
        tile_image_Transforms
        banner_image_Transforms
        image_content_image_Transforms
      }
      cta1_title
      cta2_link {
        slug
        strapi_parent {
          slug
        }
      }
      cta2_custom_link
      cta2_title
      content {
        data {
          content
        }
      }
      title
      strapi_id
      id

      guide_content {
        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT {
           __typename
            id
            left_right_block {
            cta_label
            description {
              data {
                description
              }
            }
            menu{
              slug
              strapi_parent {
                slug
              }
            }
            custom_link
            image {
              alternativeText
              url
            }
            layout
            video_url
            title
            id_name
          }
        }

        ... on STRAPI__COMPONENT_COMPONENTS_TITLE_DESC {
          id
          __typename
          title
          description {
            data {
              description
            }
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_SECONDARY_BANNER {
          __typename
          id
          image {
            url
          }
          heading
          content {
            data {
              content
            }
          }
          description {
            data {
              description
            }
          }
          video_url
          title
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_AREA_LISTINGS {
          __typename
          id
          content {
            data {
              content
            }
          }
          title
          list_items {
            area_name
            image {
              url
              alternativeText
            }
          }
        }

         ... on STRAPI__COMPONENT_PAGE_MODULES_GUIDES_CARDS {
          __typename
          id
          heading
          items {
            content {
              data {
                content
              }
            }
            image {
              alternativeText
              url
            }
            link {
              ...MenuFragment
            }
            cta_title
            custom_link
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_TWO_COLUMNS {
          __typename
          id
          cta_label
          description {
            data {
              description
            }
          }
          video_url
          menu {
            slug
            strapi_parent {
              slug
            }
          }
          custom_link
          custom_link1
           cta_label1
           menu1 {
            slug
            strapi_parent {
              slug
            }
          }
          strapi_component
          title
          layout
          image {
            url
          }
          about_developer {
            content {
              data {
                content
              }
            }
            title
          }
        }

          ... on STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS {
          __typename
            id
            cards {
              title
              content {
                data {
                  content
                }
              }
              cta {
                link {
                  slug
                  strapi_parent {
                    slug
                  }
                }
                title
              }
            }
          }

        ... on STRAPI__COMPONENT_PAGE_MODULES_VALUATION_IMAGE {
          __typename
          id
         ... ValuationImageFragment
        }

         ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          __typename
          id
          select_module
        }

         ... on STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES {
          __typename
          id
          title
          select_module
        }


        ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
          __typename
          id
          add {
            content {
              data {
                content
              }
            }
            title
          }
          title
          content {
            data {
              content
            }
          }
        }

      }
    }

    allProperties(
      filter: { publish: { eq: true }, search_type: {eq: "sales"}, status:{ne: "Off Market"}}
      sort: {fields: updatedAt, order: DESC}
      limit:6
    ) {
      edges {
        node {
          title
          crm_id
          area
          slug
          department
          status
          price
          bedroom
          building
          display_address
          description
          bathroom
         
          ggfx_results {
            src_cftle
            transforms {
              url
              format
              transform
            }
          }
          images {
            url
            srcUrl
          }
          search_type
          strapi_id
          floorarea_type
          floorarea_min
        }
      }
    }


    allStrapiPage(filter: { layout: { eq: "area_guide" } }) {
      edges {
        node {
          title
          add_page_modules {
            ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
              __typename
              ...GlobalPopUpFragment
            }
          }
        }
      }
    }
  }
`